<template>
    <div class="box">
    <el-popover placement="bottom" trigger="focus" ref="popover" >
      <div class="search-history" v-if="showOptions && showOptions.length">
        <div class="history-row" v-for="(item, index) in chunkedOptions" :key="index">
          <div class="options-wrapper">
            <div
              class="option-item"
              v-for="(option, i) in item.options"
              :key="i"
              @click="search = option.value"
            >
              <div class="option-text">{{ option.value }}</div>
              <i class="el-icon-close" @click.stop="deleteItem(option.value)" />
            </div>
          </div>
        </div>
        <div class="clear-history" @click="clearHistory">清除搜索历史</div>
      </div>
      <template v-else>
        <div class="text-grey text-center font-size-5">暂无搜索记录</div>
      </template>
  
      <el-input
        slot="reference"
        ref="headerSearchSelect"
        placeholder="请输入关键字"
        v-model="search"
        @keyup.enter.native="handleSearch"
        @blur="focus = false"
        @focus="focus = true"
        clearable
        prefix-icon="el-icon-search"
       
        class="centered-search-box"
      />
    </el-popover>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        search: "",
        focus: false,
        options: [],
        timer: null,
        chunkSize: 4,
      };
    },
    computed: {
      showOptions: {
        get() {
          const filterOptions = this.options.filter(
            (item) =>
              item.value.toLowerCase().indexOf(this.search.toLowerCase()) === 0
          );
          return this.search ? filterOptions : this.options;
        },
        set() {},
      },
      chunkedOptions() {
        let result = [];
        for (let i = 0; i < this.showOptions.length; i += this.chunkSize) {
          const options = this.showOptions.slice(i, i + this.chunkSize);
          result.push({ options });
        }
        return result;
      },
    },
    mounted() {
      this.options =
        JSON.parse(window.localStorage.getItem("appSearchOption")) || [];
      this.showOptions = [...this.options];
    },
    watch: {
      showOptions: {
        handler(val) {
          //筛选结果有数据 显示弹出框
          if (this.focus) {
            if (val.length === 0) this.$refs["popover"]?.doClose();
            else this.$refs["popover"]?.doShow();
          }
        },
        deep: true,
      },
    },
    methods: {
      //删除一条搜索记录
      deleteItem(val) {
        this.options.splice(
          this.options.findIndex((item) => item.value === val),
          1
        );
        window.localStorage.setItem(
          "appSearchOption",
          JSON.stringify(this.options)
        );
      },
      clearHistory() {
        this.options = [];
        window.localStorage.removeItem("appSearchOption");
        this.$refs["popover"]?.doClose();
      },
      //搜索保存记录并搜索
      handleSearch() {
        this.focus = false;
        const has = this.options.find((item) => item.value === this.search);
        if (this.search !== "") {
          if (!has) {
            this.options.push({ value: this.search });
            window.localStorage.setItem(
              "appSearchOption",
              JSON.stringify(this.options)
            );
          }
          //防抖
          if (this.timer) clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$emit("search", this.search);
          }, 300);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .box{
    display: flex;
    justify-content: center;
    flex-direction:column ;
    align-items: center;
    width: 100%;
    height: 100px;

    background: #f5f5f5;
  }
  ::v-deep .el-input {

    width: 500px;
    margin: 0 auto; 
    .el-input__inner {
      border-radius: 100px;
    }
  }
  
  .search-history {
    max-height: 160px;
    overflow-y: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px;
    background-color: #f2f2f2;
    width: 50%; /* add this */
    margin: 0 auto; /* add this */
  
    .history-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 8px;
  
      .options-wrapper {
      display: flex;
      justify-content: center;
      margin-right: -8px;

      .option-item {
        flex: 1;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0 8px;
        background-color: #fff;
        border-radius: 16px;
        color: #333;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          background: #f5f7fa;
        }

        .el-icon-close {
          font-size: 14px;
          color: #999;
          margin-left: 4px;
        }
      }
    }

    .clear-history {
      text-align: right;
      margin-top: 8px;
      color: #999;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .centered-search-box {
    margin: 0 auto;
    display: block !important;
    width: 100%;
  }
}
</style>
  