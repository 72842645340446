<template>
  <div class="contact-us-wrapper">
    <div class="contact-us">
    <h2>联系我们</h2>
    <span>Contact Us</span>
    </div>
    <div class="contactuscon">
      <div v-for="(item, index) in items" :key="index" class="block">
        <img :src="item.image" alt="Image">
        <p>{{ item.title }}</p>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
  </template>
  
<script>
  export default {
    name: 'ContactMethods',
    data() {
      return {
        items: [
        {
          image:require('../../assets/images/ContM1.png'),
          title: '联系电话',
          content: 'Content 1'
        },
        {
          image:require('../../assets/images/ContM2.png'),
          title: '联系邮箱',
          content: 'Content 2'
        },
        {
          image:require('../../assets/images/ContM3.png'),
          title: '联系QQ',
          content: 'Content 3'
        },
        {
          image:require('../../assets/images/ContM4.png'),
          title: '联系地址',
          content: 'Content 4'
        }
      ]
    }
  }
}
</script>
  