import Vue from 'vue'
import VueRouter from 'vue-router'
import AutoPortal from '../views/AutoPortal'
import ProductDisplay from '../views/ProductDisplay'
import About from '../views/About'
import Service from '../views/Service'
import Contact from '../views/Contact'
import NewsCenter from '../views/NewsCenter'

Vue.use(VueRouter)

const routes = [
    {
        path:'/', 
        name:'AutoPortal',
        component:AutoPortal,
        title:'衍梓自动化'
    },
    {
        path:'/ProductDisplay',
        name:'ProductDisplay',
        component:ProductDisplay,
        title:'衍梓自动化产品展示',
        meta: { requiresRefresh: true }
    },
    {
        path:'/About',
        name:'About',
        component:About,
        title:'关于我们',
        meta: { requiresRefresh: true }
    },
    {
        path:'/NewsCenter',
        name:'NewsCenter',
        component:NewsCenter,
        title:'新闻中心',
        meta: { requiresRefresh: true }
    },
    {
        path:'/Service',
        name:'Service',
        component:Service,
        title:'服务范围',
        meta: { requiresRefresh: true }
    },
    {
        path:'/Contact',
        name:'Contact',
        component:Contact,
        title:'联系我们',
        meta: { requiresRefresh: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes

    
});



export default router