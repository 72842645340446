<template>
    <header :class="[`jz_web_header g_header`, { 'sticky': navVisible }]">
      <div class="header_content g_header_content">
        <div class="jz_web_rows">
          <div class="jz_web_row_line jz_web_row_line_pc1">
            <div id="row3" class="jz_web_row jz_web_row_mobi1">
              <div class="jz_web_row__content">
                <div id="jz_nav" class="jz_nav jz_pc_nav">
                  <Nav></Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  import Nav from './Nav'
  import MobileMenu from './MobileMenu.vue';
  
  export default {
    name: 'Header',
    components: { Nav, MobileMenu },
    data() {
      return {
        navVisible: false,
      };
    },
  
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleScroll() {
        // 计算当前滚动位置
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
        // 判断是否应该显示导航菜单
        if (scrollTop >= 50) {
          this.navVisible = true;
        } else {
          this.navVisible = false;
        }
      },
    },
  }
  </script>
  
  <style>
   .jz_web_header {
      /* position: absolute; 初始值为absolute */
      top: auto; /* 初始值为auto */
      left: 0;
      width: 100%;
      z-index: 999;
   }
  
  .sticky {
      position: fixed; /* 滚动超过100px后将position属性改为fixed */
      top: 0; /* 固定在页面顶部 */
  }
  </style>
  