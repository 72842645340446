<template>
    <footer :class="[`jz_web_footer g_footer`]">
      <div class="jz_web_rows">
        <div class="jz_web_row_line jz_web_row_line_pc1">
          <div class="jz_web_row jz_web_row1 jz_section_row " src-original="//20991040.s61i.faiusr.com/2/AD0IwJiBChACGAAghPeT7wUo1Kfx-wcwgA84VQ.jpg" style="background-image: url(&quot;//20991040.s61i.faiusr.com/2/AD0IwJiBChACGAAghPeT7wUo1Kfx-wcwgA84VQ.jpg.webp&quot;);" data-loaded="true"><!---->
            <div class="s_footer jz_project">
              <p class="s_footer_support_container">
                <span class="s_footer_support">
                  <a href="#" target="_blank" class="s_footer_anchor jz_hover">
                    <svg class="s_footer_support_icon"><use xlink:href="#icon_site_logo_no_color"></use></svg>
                    衍梓智能-太阳能光伏清洁机器人的研发
                  </a>
                </span>
              </p>
            </div>
            <div class="form jz_module jz_project">
              <div class="module_wrap">
                  <div style="text-align: center;">
                    <font color="#ffffff">
                      <span style="font-size: 14px;">© 2023 上海衍梓智能科技有限公司</span>
                    </font>
                    <span style="font-size: 14px;"><br></span>
                  </div>
              </div> 
            </div> 
          </div>
        </div>
      </div>
    </footer>
</template>
<script>
    export default{
        name:'Footer',
    }
</script>