<template>
    
      <SerRanCon/>
    
  </template>
  
  <script>
  import SerRanCon from './SerRanCon.vue';
  export default {
    name: 'SerCon',
    components:{SerRanCon}
  };
  </script>
  
  <style scoped>
  @import url('../../../assets/fonts/font.css');
  .service-range {
    display: flex;
  }
  
 
  </style>
  