<template>
    <div class="homeabout-image-text-container">
      <div class="homeabout-image-container" :style="{ backgroundImage: `url(${imageUrl})` }" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="homeabout-image-overlay" v-if="hover"></div>
      </div>
      <div class="homeabout-text-container">
        <p>{{ description }}</p>
        
        <div class="homeabout-about-button-wrapper">
          <button class="btn btn-primary about-button" @click="goToLink">查看更多</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      linkUrl: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        hover: false,
      };
    },
    methods: {
    goToLink() {
      window.location.href = this.linkUrl;
    }
  }
};
</script>
  