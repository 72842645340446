<template>
    <Layout>
        <img src="../assets/images/product1.png" width="100%" height="200px" alt="">
       <AboutUs/>
        <TimeLine/>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import AboutUs from '@/components/About/AboutUs.vue';
import TimeLine from '@/components/About/TimeLine.vue';

export default{
    name:'About',
    components:{Layout,AboutUs,TimeLine},
    data(){
           return{
            
           }
        },
    // mounted() {
    //     if (this.$route.meta.requiresRefresh) {
    //     window.location.reload()
    //     }
    // }
}


    
</script>