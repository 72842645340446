<template>
    <div>
      <div class="aboutcon-container">
      <div class="aboutcon-text-container">
        <h2>{{ title }}</h2>
        <p>{{ content }}</p>
      </div>
    </div>
    <div class="aboutcon-imgcon">
      <div v-for="(item, index) in items" :key="index" class="block">
        <img :src="item.image" alt="Image">
        <h3>{{ item.title }}</h3>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
  
  </template>
  
  <script>
  export default {
    name:'AboutUs',

    
    data() {
      return {
        title: '关于我们',
        content: '上海衍梓自动化科技有限公司，在国家倡导碳达峰，碳中和以及智能制造的大背景下应运而生的智能解决方案的科技公司。从光伏电站的发展趋势来看，分布式电站越来越多，但电站清洁问题一直没能很好的的解决，为此衍梓自动化致力于太阳能光伏清洁机器人的研发及应用，以及清洁工具配套一体化，从而解决电站清洁难，危险系数高，劳动强度过大，劳动力短缺的难题。致力于真正为太阳能光伏运维企业助一臂之力。',
        items: [
        {
          image:require('../../assets/images/AboutUs1.png'),
          title: '操作简单',
          content: 'Content 1'
        },
        {
          image:require('../../assets/images/AboutUs2.png'),
          title: '优质服务',
          content: 'Content 2'
        },
        {
          image:require('../../assets/images/AboutUs3.png'),
          title: '业务覆盖广泛',
          content: 'Content 3'
        },
        {
          image:require('../../assets/images/AboutUs4.png'),
          title: 'xxxx',
          content: 'Content 4'
        }
      ]
    
      };
    },
    methods:{
    goToLink() {
        window.location.href = this.linkUrl;
    }
  }
}
  </script>

