<template>
    <div class="product-wrapper">
    <div class="product-grid">
      <div v-for="product in products" :key="product.id" class="product-item" @mouseover="hover[product.id] = true" @mouseleave="hover[product.id] = false">
        <img :src="product.image" :alt="product.name" class="product-image" :class="{ 'hover': hover[product.id] }">
        <h3 class="product-name">{{ product.name }}</h3>
        <p class="product-text">{{ product.text }}</p>
      </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    name:'ProductContent',
    data() {
      return {
        hover: {},
        products: [
          { id: 1, name: 'product 1', text: '衍梓智能', image: require('../../../assets/images/1.jpg') },
          { id: 2, name: 'product 2', text: '衍梓智能', image: require('../../../assets/images/2.jpg') },
          { id: 3, name: 'product 3', text: '衍梓智能', image: require('../../../assets/images/3.jpg') },
          { id: 4, name: 'product 4', text: '衍梓智能', image: require('../../../assets/images/4.jpg') },
          { id: 5, name: 'product 5', text: '衍梓智能', image: require('../../../assets/images/5.jpg') },
          { id: 6, name: 'product 6', text: '衍梓智能', image: require('../../../assets/images/2.jpg') },
        ],
      };
    },
  };
  </script>
  
  