<template>
    <Layout>
        <img src="../assets/images/product1.png" width="100%" height="200px" alt="">
        <News />

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import News from '@/components/News/News.vue';
    

    export default{
        name:'NewsCenter',
        components:{Layout,News},
        data(){
            return {
               
            }
            }
        }
        

</script>