<template>
    <div class="jz_mobi_header g_mobi_header">
        <div class="mobi_nav g_mobi_header_content">
                <div class="jz_nav_menu" >
                    <div class="menu_body" @click.prevent="AppFunctions.toggleClass('.jz_nav_menu','jz_nav_wrap_active')">
                        <div class="nav_menu_item menu_item_top">
                            <div class="item_rect"></div>
                        </div>
                        <div class="nav_menu_item menu_item_center">
                            <div class="item_rect"></div>
                        </div>
                        <div class="nav_menu_item menu_item_bottom">
                            <div class="item_rect"></div>
                        </div>
                    </div>
                </div>
                <div id="jz_nav" class="jz_nav jz_mobi_nav">
                <MobileNav/>
                </div>
                <div class="mobi_nav_blocker"></div>
            <div class="g_mobi_header__rightbar" style="line-height: 50px;">
            </div>
        </div>
    </div>       
</template>

<script>
import Nav from './Nav.vue';
import AppFunctions from '../../../helpers/AppFunctions';
import MobileNav from './MobileNav.vue'


    export default{
        name:'MobileMenu',
        components:{Nav,MobileNav},
        data() {
            return {
                AppFunctions
            }
        },
    }
</script>