<template>
    <li class="nav_item_septal">
        <span class="jz_nav_partition_line jz_nav_partition_line_style0"> | </span>
        <span class="jz_nav_partition_line jz_nav_partition_line_style1"> / </span>
    </li> 
</template>

<script>
    export default{
        name:'Septal',
        
    }
</script>