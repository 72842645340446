<template>
    <div class="contractcon-container">
        <div class="contractcon-left-section">
            <div class="section-one">
                <button @click="gotoLink" class="contractbut">联系客服</button>
                <div class="con">
                    <div class="con1">热线电话：</div>
                    <div class="con2"> {{ phoneNumber }}</div>
                </div>
                <div class="con">
                    <div class="con1">联系邮箱：</div>
                    <div class="con2"> {{ emailAddress }}</div>
                </div>
                <div class="con">
                    <div class="con1">工作时间：</div>
                    <div class="con2"> {{ worktime }}</div>
                </div>
                </div>
                <div class="qrcode">
                <img src="../../assets/images/qrcode.jpg" />
            </div>
        </div>
        <div class="contractcon-right-section">
            <h2>若有需要，可以留下您的联系方式</h2>
            <form>
                
                <!-- <span class="asterisk">*</span> -->
                <input type="text" id="name" v-model="name" placeholder="请输入您的姓名"/>
                <!-- <span class="asterisk">*</span> -->
                <input type="text" id="phonenumber" v-model="phonenumber" placeholder="请输入手机号码"/>
                <!-- <span class="asterisk">*</span> -->
                <input type="text" id="emile" v-model="emile" placeholder="请输入邮箱"/>
                <!-- <input type="text" id="note" v-model="email" placeholder="请输入您想要说的话"/> -->
                <textarea type="text" id="note" v-model="asterisk" placeholder="请输入多行文本">
                </textarea>
                <button @click.prevent="submitForm" class="submitForm">提交</button>
            </form>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ContactUs",
    data() {
      return {
        phoneNumber: "123-456-7890",
        emailAddress: "***@***",
        worktime: "周一到周五 09:00-18:00",
        name: "",
        phonenumber: "",
        emile:"",
        asterisk:""
      };
    },
    methods: {
      
      submitForm() {
        // 处理表单提交操作
        console.log("表单已提交");
      },
      gotoLink(){
        window.location.href = '/contract'
      }
    },
  };
  </script>
<style scoped>
    
.contractcon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .contractcon-container .contractcon-left-section {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .contractcon-container .contractcon-left-section .section-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    font-family: 微软雅黑;
  }
  
  .contractcon-container .contractcon-left-section .qrcode {
    text-align: center;
  }
  
  .contractcon-container .contractcon-right-section {
    flex: 1;
    padding: 20px;
  }
  .contractcon-container .contractcon-right-section .asterisk{
    color: red;
  }

  .contractcon-container .contractcon-right-section span{
    font-size: 14px;
    color: #666666;
    margin-bottom: 10px
  }
  
  .contractcon-container .contractcon-right-section #name,#emile,#phonenumber
 {
    height: 44px;
    line-height: 44px;

    padding-left:14px ;
   
  }
  .contractcon-container .contractcon-right-section #note{
    height: 100px;
    padding: 9px 14px;
    font-family: 微软雅黑;
  }
  .contractcon-container .contractcon-right-section input,textarea{
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    font-size: 5px;
    background-color: #cccccc26;
    border: none;
  }
  .contractcon-container .contractcon-left-section .contractbut{
    width: 50%;
    background-color: #1e50ae;
    color: #fff;
    border: none;
    border-radius: 30px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .contractcon-container .contractcon-left-section .contractbut:hover {
    background-color: #0062cc;
  }
  .contractcon-container .contractcon-right-section .submitForm{
    width: 100px;
    height: 30PX;
    border:1px solid #1e50ae;
    color: #1e50ae;
    background-color: #fff;
    
  }
  .contractcon-container .contractcon-right-section .submitForm:hover {
    background-color: #0062cc;
    color: #fff;
  }

  .contractcon-container .contractcon-left-section .con{
    margin-top: 30px;
    display: flex;
  }
  .contractcon-container .contractcon-left-section .con1{
    display: inline-block;
    width: 40%;
    font-size: 14px;
    height: 20px;
  }
  .contractcon-container .contractcon-left-section .con2{
    display: inline-block;
    width: 50%;
    font-size: 14px;
  }
</style>