<template>
    <main>
        <Header> </Header>
        <div class="main-content">
            <slot></slot>
        </div>
        <Footer></Footer>
        <!-- <MobileMenuVue/> -->
    </main>
</template>

<script>
import Header from './header/Header'
import Footer from './footer/Footer'
import MobileMenuVue from './header/MobileMenu.vue'

export default {
    name:'Layout',
    components:{Header,Footer,MobileMenuVue}
}
</script>