<template>
    <div class="jz_nav_content" >
        <i class="nav_icon_prev nav_icon_direction"></i> 
        <i class="nav_icon_next nav_icon_direction"></i> 
        <ul class="jz_nav_list">
            <li class="jz_nav_item jz_hover nav_item2 jz_theme_border_hover_color">
                <router-link to="/" exact exact-active-class="active" class="nav_item_text">首页</router-link>
            </li>
            <septal/>
            <li class="jz_nav_item jz_hover"><router-link to="/ProductDisplay" exact exact-active-class="active" class="nav_item_text">产品展示</router-link></li>
            <septal/>
            <li class="jz_nav_item jz_hover" ><router-link to="/About" exact exact-active-class="active" class="nav_item_text" >关于我们</router-link></li>
            <Logo/>
            <li class="jz_nav_item jz_hover" ><router-link to="/NewsCenter" exact exact-active-class="active" class="nav_item_text">新闻中心</router-link></li>
            <septal/>
            <li  class="jz_nav_item jz_hover" ><router-link to="/Service" exact exact-active-class="active" class="nav_item_text">服务范围</router-link></li>
            <septal/>
            <li  class="jz_nav_item jz_hover" ><router-link to="/Contact" exact exact-active-class="active" class="nav_item_text">联系我们</router-link></li>
        </ul>
    </div>
</template>

<script>
    import Logo from "../../elements/logo/Logo";
    import Septal from "./Septal.vue";
    export default {
        name: 'Nav',
        components: {Logo,Septal},
 
  
    }
</script>

<style scoped>
.active {
    color: #1e50ae;
}

</style>