<template>
    <div class="contractcon-container">
        <div class="contractcon-left-section">
            <div class="section-one">
                <button @click="gotoLink" class="contractbut">联系客服</button>
                <div class="con">
                    <div class="con1">热线电话：</div>
                    <div class="con2"> {{ phoneNumber }}</div>
                </div>
                <div class="con">
                    <div class="con1">联系邮箱：</div>
                    <div class="con2"> {{ emailAddress }}</div>
                </div>
                <div class="con">
                    <div class="con1">工作时间：</div>
                    <div class="con2"> {{ worktime }}</div>
                </div>
                </div>
                <div class="qrcode">
                <img src="../../../assets/images/qrcode.jpg" />
            </div>
        </div>
        <div class="contractcon-right-section">
            <h2>欢迎留言</h2>
            <form>
                <span class="name">您的名字 </span>
                <span class="asterisk">*</span>
                <input type="text" id="name" v-model="name" placeholder="请输入您的姓名"/>
                <span class="note">留言 </span>
                <span class="asterisk">*</span>
                <!-- <input type="text" id="note" v-model="email" placeholder="请输入您想要说的话"/> -->
                <textarea type="text" id="note" v-model="asterisk" placeholder="请输入您想要说的话">
                </textarea>
                <button @click.prevent="submitForm" class="submitForm">提交</button>
            </form>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ContactUs",
    data() {
      return {
        phoneNumber: "123-456-7890",
        emailAddress: "***@***",
        worktime: "周一到周五 09:00-18:00",
        name: "",
        asterisk: "",
      };
    },
    methods: {
      
      submitForm() {
        // 处理表单提交操作
        console.log("表单已提交");
      },
      gotoLink(){
        window.location.href = '/contract'
      }
    },
  };
  </script>
  