import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/style.scss'
import './assets/fonts/font.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import AMap from 'vue-amap';
import AMap from 'vue-amap'
    Vue.use(AMap);

      // 初始化vue-amap
    AMap.initAMapApiLoader({
      // 高德key
      key: 'f90db1137d70872d53d65e94f2cf033b',
     // 插件集合 （插件按需引入）
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'] ,
     //AMap.Geolocation定位服务插件。融合了浏览器定位、高精度IP定位、安卓定位sdk辅助定位等多种            手段，提供了获取当前准确位置、获取当前城市信息、持续定位(浏览器定位)等功能。

    });

import { VueAxios } from "@/api/request"
Vue.use(VueAxios, router)
// 全局注册 element 组件库
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
