<template>
    <Layout>
        <img src="../assets/images/product1.png" width="100%" height="200px" alt="">
        <Search/>
        <ProductCon :title="myTitle" :products="myProducts"/>
        <el-divider></el-divider>
        <ProductCon :title="myTitle1" :products="myProducts1"/>
        <el-divider></el-divider>
        <ProductCon :title="myTitle2" :products="myProducts2"/>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Search from '@/components/elements/production/Search.vue';
    import ProductCon from '@/components/elements/production/ProductCon.vue';
    import {findAutoProducts} from '@/api/manage'

    export default{
        name:'ProductDisplay',
        components:{Layout,Search,ProductCon},
        data(){
            return{
                myTitle: "My Product Display",
                myTitle1: "My Product Display1",
                myTitle2: "My Product Display2",

      myProducts: [
        {
          name: "Product A",
          image: "https://via.placeholder.com/150",
          link: "http://www.producta.com",
        },
        {
          name: "Product B",
          image: "https://via.placeholder.com/150",
          link: "http://www.productb.com",
        },
        {
          name: "Product C",
          image: "https://via.placeholder.com/150",
          link: "http://www.productc.com",
        },
      ],
      myProducts1: [
        {
          name: "Product A1",
          image: "https://via.placeholder.com/150",
          link: "http://www.producta.com",
        },
        {
          name: "Product B1",
          image: "https://via.placeholder.com/150",
          link: "http://www.productb.com",
        },
        {
          name: "Product C1",
          image: "https://via.placeholder.com/150",
          link: "http://www.productc.com",
        },
      ],
      myProducts2: [
        {
          name: "Product A2",
          image: "https://via.placeholder.com/150",
          link: "http://www.producta.com",
        },
        {
          name: "Product B2",
          image: "https://via.placeholder.com/150",
          link: "http://www.productb.com",
        },
        {
          name: "Product C2",
          image: "https://via.placeholder.com/150",
          link: "http://www.productc.com",
        },
      ],
    }
  },
  created() {
      //Step.2 加载用户数据
      findAutoProducts().then((res) => {
        if (res.code ==200) {
          alert(res.data)
        }
      })
    },
        
    }
</script>