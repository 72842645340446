<template>
    <Layout>
        <Banner/>
        <!-- "https://0.ss.faisys.com/image/rimage/module/online_flv/Sun.mp4" -->
        <ProductContent/>
        <AboutContent 
        description="关于我们" 
        linkUrl="/About" 
        imageUrl="https://via.placeholder.com/150" 
        />
        <BrandContent linkUrl="/About"/>
        <VideoPlayer/>
        <Service/>
        <SerCon/>
        <ContractCont/>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    // import Banner1 from '../components/elements/banner/Banner1';
    import Banner from '../components/elements/banner/Banner';

    import ProductContent from '@/components/elements/homecontent/ProductContent.vue';
    import AboutContent from '@/components/elements/homecontent/AboutContent.vue';
    import BrandContent from '@/components/elements/homecontent/BrandContent.vue';
    import VideoPlayer from '@/components/elements/video/VideoPlayer.vue';
    import Service from '@/components/elements/homecontent/Service.vue';
    import SerCon from '@/components/elements/homecontent/SerCon.vue';
    import ContractCont from '@/components/elements/homecontent/ContractCont.vue';

    export default{
        name:'Demo',
        components:{Layout,Banner,ProductContent,AboutContent,BrandContent,VideoPlayer, Service,SerCon,ContractCont},
        
    }
</script>