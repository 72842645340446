import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/yanzi-erpplus'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = process.env.NODE_ENV === 'production'?"/yanzi-erpplus":"http://101.34.188.225:8080/yanzi-erpplus";
//console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 300000 // 请求超时时间
})

const err = (error) => {
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  // const token = Vue.ls.get(ACCESS_TOKEN)
  // if (token) {
  //   config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  // }
  return config
},(error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
    return response.data
  }, err)

const installer = {
  vm: {},
  install (Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}