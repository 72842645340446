<template>
    <Layout>
        <img src="../assets/images/product1.png" width="100%" height="200px" alt="">
        <SerCon/>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SerCon from '@/components/elements/homecontent/SerCon.vue';


    export default{
        name:'ProductDisplay',
        components:{Layout,SerCon},
        data(){
            return{
 
           }
        }
        
    }
</script>