<template>
  <div class="amap-wrap">
    <el-amap vid="amapContainer" :zoom="zoom" :events="events" class="amap-demo"></el-amap>
  </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
export default {
  name: "amapMap",
  data () {
    return {
      map: null,
      zoom: 17,
      events: {},
    };
  },
  mounted () {
    lazyAMapApiLoaderInstance.load().then(() => {
      this.map = new AMap.Map("amapContainer", {
        center: new AMap.LngLat(121.42769, 31.03033),
        zoom: this.zoom,
      });
      var marker = new AMap.Marker({
        map: this.map,
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [121.42769, 31.03033],
      });
      marker.setMap(this.map);

      var text = new AMap.Text({
      text: '上海衍梓智能',
      position: [121.42769, 31.03033],
      offset: new AMap.Pixel(-0, -50),
      zIndex: 101,
      bubble: true,
      draggable: false,
      cursor: 'pointer',
      style: {
        'background-color': '#fff',
        'border': '1px solid #0088ff',
        'padding': '5px',
      }
    });
    text.setMap(this.map);
    });
  },
};
</script>
<style scoped>
.amap-wrap {
  width: 80%;
  height: 300px;
  margin: 20px auto;

}
</style>

