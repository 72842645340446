<template>
    <div class="brandcon-container">
      <div class="brandcon-text-container">
        <h2>{{ title }}</h2>
        <p>{{ content }}</p>
        <div class="brandcon-button-warpper">
            <button class="brandcon-but" @click="goToLink">查看更多</button>
        </div>
      </div>
      <div class="brandcon-image-container">
        <img src="../../../assets/images/1.jpg" alt="description of image" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:'BrandContent',
    props: {
      linkUrl: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        title: '品牌理念',
        content: '上海衍梓自动化科技有限公司，在国家倡导碳达峰，碳中和以及智能制造的大背景下应运而生的智能解决方案的科技公司。从光伏电站的发展趋势来看，分布式电站越来越多，但电站清洁问题一直没能很好的的解决，为此衍梓自动化致力于太阳能光伏清洁机器人的研发及应用，以及清洁工具配套一体化，从而解决电站清洁难，危险系数高，劳动强度过大，劳动力短缺的难题。致力于真正为太阳能光伏运维企业助一臂之力。',
      };
    },
    methods:{
    goToLink() {
        window.location.href = this.linkUrl;
    }
  }
}
  </script>

  