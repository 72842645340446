<template>
    <div class="video-player">
        <div class="image-container" @mouseover="showPlayButton=true" @mouseout="showPlayButton=false">
            <img class="videoimage"
            :src="coverImage"
            />
            <img v-show="showPlayButton" class="play-button" src="../../../icons/svg/videoplay-icon.svg" @click="playVideo"/>
            
        </div>
        <div v-show="showVideoModal" class="modal-overlay">
            <div class="modal-content">
                <video controls autoplay>
                    <source :src="videoUrl" type="video/mp4" />
                </video>
                <div class="modal-close" @click="closeVideoModal">
                    <svg width="100%" height="100%" viewBox="0 0 64 64">
                        <line x1="16" y1="16" x2="48" y2="48" />
                        <line x1="48" y1="16" x2="16" y2="48" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPlayButton: false,
        showVideoModal: false,
        videoUrl: "https://0.ss.faisys.com/image/rimage/module/online_flv/Sun.mp4",
        coverImage: "https://via.placeholder.com/150",
      };
    },
    methods: {
      playVideo() {
        this.showPlayButton = false;
        this.showVideoModal = true;
      },
      closeVideoModal() {
        this.showPlayButton = true;
        this.showVideoModal = false;
      },
    },
  };
  </script>
  
  <!-- <style scoped>
  .video-player {
    position: relative;
    display: inline-block;
    height: 300px;
    width: 100%;
  }
  .video-player .image-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

  }

img {
    max-width: 100%;
    width: 100%;
    height: 100%;
  } 
   
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    
    transition: opacity 0.2s,transform 0.2s;
    opacity: 0;
  }
  
  .play-button:hover {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  
  svg {
    fill: currentColor;
    width: 40%;
    height: 40%;
    margin: 30%;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
  position: relative;
  width: 80vw; /* 设置视频弹窗宽度为视口宽度的80% */
  height: 80vh; /* 设置视频弹窗高度为视口高度的80% */
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  max-width: 100%; /* 设置视频最大宽度为100% */
  max-height: 100%; /* 设置视频最大高度为100% */
  outline: none;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-close svg {
  stroke: #fff;
  stroke-width: 3;
}

  </style>
   -->