<template>
    <div class="servicerange">
        <span style="font-size: 26px;">服务范围</span>
        <span style="font-size: 12px;">SERVICE</span>
    </div>
</template>
<script>
    export default{
        name:'ervice'
    }
</script>
<style>
.servicerange{
    height: 100px;      
}
.servicerange span{
    font-family: 微软雅黑;
    display: block;
    text-align: center;
    color:#666;
    padding: 5px;
}
</style>