<template>
    <div class="block">
  <el-timeline>
    <el-timeline-item timestamp="2023/4/12" placement="top">
      <el-card>
        <h4>2022</h4>
        <p>123</p>
      </el-card>
    </el-timeline-item>
    <el-timeline-item timestamp="2022/4/3" placement="top">
      <el-card>
        <h4>2021</h4>
        <p>234</p>
      </el-card>
    </el-timeline-item>
    <el-timeline-item timestamp="2021/4/2" placement="top">
      <el-card>
        <h4>2020</h4>
        <p>123</p>
      </el-card>
    </el-timeline-item>
  </el-timeline>
</div>
</template>
  
  <script>
    export default {
      data() {
        return {
          activities: [{
            content: '支持使用图标',
            timestamp: '2018-04-12 20:46',
            size: 'large',
            type: 'primary',
            icon: 'el-icon-more'
          }, {
            content: '支持自定义颜色',
            timestamp: '2018-04-03 20:46',
            color: '#0bbd87'
          }, {
            content: '支持自定义尺寸',
            timestamp: '2018-04-03 20:46',
            size: 'large'
          }, {
            content: '默认样式的节点',
            timestamp: '2018-04-03 20:46'
          }]
        };
      }
    };
  </script>
  <style>
    .block{
        width: 50%;
        margin: auto;
    }
</style>