<template>
    <Layout>
        <img src="../assets/images/product1.png" width="100%" height="200px" alt="">
       <ContactMethods/>
       <MapComponent/>
       <!-- 121.42769 31.03033 -->
       <ContactUs/>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import MapComponent from '@/components/Contact/MapComponent.vue';
    import ContactMethods from '@/components/Contact/ContactMethods.vue';
    import ContactUs from '@/components/Contact/ContactUs.vue';

    export default{
        name:'About',
        components:{Layout,MapComponent,ContactMethods,ContactUs},
        data(){
            return {}
            }
        }
        

</script>