<template>
  <div class="service-range">
    <div class="service-range__section" v-for="item in items" :key="item.id">
      <div class="service-range__section-heading">
        <div class="service-range__section-number">
            0{{ item.id }}
        </div>
        <div class="service-range__section-title">{{ item.title }}</div>
        <div class="service-range__item-description">{{ item.description }}</div>
          <img class="service-range__item-image" src="item.mageUrl" alt="放图" />
      </div>
    </div>
  </div>
</template>

<script>
import '../../../assets/fonts/font.css'
    export default{
        name:'SerRanCon',
        data(){
          return{
            items:[ {
          id: 1,
          title: 'Item 1',
          description: 'This is the first item.',
          imageUrl: 'https://via.placeholder.com/150'
        },
        {
          id: 2,
          title: 'Item 2',
          description: 'This is the second item.',
          imageUrl: 'https://via.placeholder.com/150'
        },
        {
          id: 3,
          title: 'Item 3',
          description: 'This is the third item.',
          imageUrl: 'https://via.placeholder.com/150'
        }
          ]
        }
      }
    }
</script>

