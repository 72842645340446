<template>
    <div class="news-container">
      <div v-for="(group, groupIndex) in newsGroup" :key="groupIndex" class="news-group">
        <div v-for="(news, newsIndex) in group" :key="news.id" class="news-item" @mouseenter="hoverNews(newsIndex)" @mouseleave="unhoverNews(newsIndex)">
          <img :src="news.image" alt="news image" class="news-image">
          <div class="news-info">
            <h3 class="news-title">{{ news.title }}</h3>
            <p class="news-time">{{ news.time }}</p>
            <p class="news-content">{{ news.content }}</p>
            <div class="arrow" @click="goToDetail(news.id)">→</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'News',
    data() {
      return {
        newsList: [], // 接收后端传来的新闻数据
        newsGroup: [], // 分组后的新闻数据，每个元素为一个包含三个新闻块的数组
        hoveringNewsIndex: null // 当前鼠标悬浮在的新闻块的索引，初始化为null表示没有悬浮任何块
      }
    },
    mounted() {

      this.newsList = [
      { id: 1, image: require('../../assets/images/news1.jpg'), title: '新闻标题1', time: '2023年4月20日', content: '新闻内容1', hovering: false },
      { id: 2, image: require('../../assets/images/news2.jpg'), title: '新闻标题2', time: '2023年4月21日', content: '新闻内容2', hovering: false },
      { id: 3, image: require('../../assets/images/news3.jpg'), title: '新闻标题3', time: '2023年4月22日', content: '新闻内容3', hovering: false },
      { id: 4, image: require('../../assets/images/news4.jpg'), title: '新闻标题4', time: '2023年4月23日', content: '新闻内容4', hovering: false },
      { id: 5, image: require('../../assets/images/news5.jpg'), title: '新闻标题5', time: '2023年4月24日', content: '新闻内容5', hovering: false },
      { id: 6, image: require('../../assets/images/news6.jpg'), title: '新闻标题6', time: '2023年4月25日', content: '新闻内容6', hovering: false }
      ]
      // 将新闻数据分组，每个分组包含三个新闻块
      for (let i = 0; i < this.newsList.length; i += 3) {
        this.newsGroup.push(this.newsList.slice(i, i + 3))
      }
    },
    methods: {
      hoverNews(index) {
        this.newsList[index].hovering = true // 鼠标悬浮时设置hovering为true
      },
      unhoverNews(index) {
        this.newsList[index].hovering = false // 鼠标移出时将hovering重置为false
      },
      goToDetail(id) {
        // 根据新闻id跳转到详情页
        window.location.href = `/detail?id=${id}`
      }
    }
  }
  </script>
  
  <style scoped>
  .news-container {
    /* margin-top: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
    width: 80%;
  }
  
  .news-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    
  }
  .news-item {
  position: relative;
  width: calc(33.3% - 20px); /* 减去左右两个10像素的外边距 */
  margin-right: 10px; /* 右侧外边距 */
  margin-left: 10px; /* 左侧外边距 */
  padding: 20px;
  background-color: #fff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, .1); */
  transition: all .3s ease-in-out;
  height: 400px;
}

  
  .news-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
}

.news-image {
width: 100%;
height: 40%;
margin-bottom: 10px;
}

.news-info {
position: absolute;
/* bottom: 20px; */
left: 20px;
right: 20px;

transition: all .3s ease-in-out;
}

.news-info:hover {
transform: translateY(-5px);
}

.news-title {
font-size: 24px;
font-weight: bold;
margin-bottom: 10px;
}

.news-time {
font-size: 14px;
margin-bottom: 10px;
}

.news-content {
font-size: 16px;
line-height: 1.5;
height: 60px;
overflow-y: hidden;
}

.arrow {
position: absolute;
bottom: 20px;
right: 20px;
width: 30px;
height: 30px;
line-height: 30px;
text-align: center;
border-radius: 50%;
background-color: #fff;
box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
cursor: pointer;
transition: all .3s ease-in-out;
opacity: 0;
color: #999999;
}

.news-item:hover .arrow {
opacity: 1;
transform: translateX(5px);
}
</style>

