<template>
    <div class="product-display">
      <h2>-{{title}}-</h2>
      <p>123</p>
      <div class="products">
        <div v-for="(product, index) in products" :key="index" class="product">
          <a :href="product.link">
            <img :src="product.image" :alt="product.name" @mouseover="addShadow(index)" @mouseleave="removeShadow(index)">
            <p class="name" @click="goToDetails(product.link)">{{ product.name }}</p>
          </a>
        </div>
      </div>
    </div>
  </template>
  
<script>
  export default {
    name: 'ProductCon',
    props: {
      title: String,
      products: Array
    },
    methods: {
      addShadow(index) {
        document.querySelectorAll('.product img')[index].classList.add('shadow');
      },
      removeShadow(index) {
        document.querySelectorAll('.product img')[index].classList.remove('shadow');
      },
      goToDetails(link) {
        window.location.href = link;
      }
    }
  }
  </script>
  
  <style scoped>
  .product-display {
    text-align: center;
    color: #666666;
    font-size: 15px;
    max-width: 800px; /* 设定产品展示区域最大宽度 */
    margin: 0 auto; /* 居中 */
    padding-top: 30px; /* 第一行上方添加间距 */
    padding-bottom: 20px

  }
  
 .products {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
.product {
    background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
      overflow: hidden;
  
  }
  
  .product:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  }
  
  .product img {
    padding-top: 10px;
    max-width: 100%;
    height: auto;
  }
  
  .name {
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  </style>
  