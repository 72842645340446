<template> 
    <div class="jz_nav_content">
        <i class="nav_icon_prev nav_icon_direction"></i> 
        <i class="nav_icon_next nav_icon_direction"></i> 
        <ul class="jz_nav_list">
            <li  class="jz_nav_item jz_hover nav_item2 jz_nav_item_selected jz_theme_border_color jz_theme_font_color">
                <div class="nav_item_text_wrap"> 
                    <a href="#"></a>
                    首页
                </div>
            </li>
                <li class="jz_nav_item jz_hover">
                <div class="nav_item_text_wrap">
                    <router-link to="/ProductDisplay">产品展示</router-link>
                </div> 
            </li>
            <li class="jz_nav_item jz_hover">
                <div class="nav_item_text_wrap">
                    <router-link to="/About">关于我们</router-link>                      
                </div>
            </li>
            <li class="jz_nav_item jz_hover">
                <div class="nav_item_text_wrap">
                    <router-link to="/NewsCenter">新闻中心</router-link>
                </div>
            </li>
            <li _colid="105" class="jz_nav_item jz_hover nav_item105">
                <div class="nav_item_text_wrap">
                    <router-link to="/Service">服务范围</router-link>
                </div>
            </li>
            <li _colid="102" class="jz_nav_item jz_hover nav_item102">
                <div class="nav_item_text_wrap">
                    <router-link to="/Contanct">联系我们</router-link> 
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Septal from './Septal.vue';
export default{
    name:'MobileNav',
    components:{Septal}
}
</script>